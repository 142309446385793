import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { store, tatamiTheme, TranslationContextProvider } from "@tatami/shared";
import React from "react";
import ReactDOM from "react-dom/client";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { MemoryRouter as Router } from "react-router-dom";
import { FirebaseAppProvider } from "reactfire";
import App from "./App";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
export interface FightEmbeddedProps {
  targetElId: string;
  fightUid: string;
  chat: boolean;
  title: boolean;
  playable?: boolean;
  cssBaseline?: boolean;
}

const firebaseConfig = {
  apiKey: "AIzaSyC5_1fr45qcvmHyZeIh_zgDJDQ5XHYzn6M",
  authDomain: "vs-show.firebaseapp.com",
  databaseURL: "https://vs-show.firebaseio.com",
  projectId: "vs-show",
  storageBucket: "vs-show.appspot.com",
  messagingSenderId: "580801454004",
  appId: "1:580801454004:web:35abd8c2c9219977cb5ce1",
};

export const fight = (config: FightEmbeddedProps) => {
  const root = ReactDOM.createRoot(
    document.getElementById(config.targetElId) as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={tatamiTheme}>
        <Provider store={store}>
          <Router>
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
              <TranslationContextProvider value={{ useTranslation }}>
                {config.cssBaseline ? <CssBaseline /> : null}
                <App config={config} />
              </TranslationContextProvider>
            </FirebaseAppProvider>
          </Router>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
};
