/** @jsxImportSource @emotion/react */

import { Grid, useTheme } from "@mui/material";
import {
  BleacherContextProvider,
  ChatBox,
  ChatContextProvider,
  selectBleacher,
  selectChat,
  selectFight,
  selectVoteResults,
  useAppSelector,
  useBleacher,
} from "@tatami/shared";
import { useRef } from "react";
import Fight from "./Fight";

export interface BleacherProps {
  fightUid: string;
  chatEnable: boolean;
  titleEnabled: boolean;
  playable?: boolean;
}

function Bleacher({
  fightUid,
  chatEnable,
  titleEnabled,
  playable,
}: BleacherProps) {
  const wsSession = useRef<WebSocket>();
  const theme = useTheme();

  const fight = useAppSelector(selectFight);
  const bleacher = useAppSelector(selectBleacher);
  const voting = useAppSelector(selectVoteResults);
  const chat = useAppSelector(selectChat);

  useBleacher(wsSession, fightUid);

  if (!wsSession.current || !fight || !bleacher.media) {
    return null;
  }
  return (
    <BleacherContextProvider
      value={{
        wsSession: wsSession.current,
        fight: fight,
        media: bleacher.media,
        chat: chat,
        voting: voting,
      }}
    >
      <Grid
        container
        spacing={0}
        sx={
          {
            // [theme.breakpoints.down("sm")]: {
            //   maxHeight: "80vh",
            // },
          }
        }
      >
        <Grid item xs={chatEnable ? 8 : 12}>
          <Fight titleEnabled={titleEnabled} playable={playable} />
        </Grid>
        {chatEnable && (
          <ChatContextProvider
            value={{ chat, wsSession: wsSession.current, fight }}
          >
            <Grid
              item
              xs={4}
              // xs={12}
              sx={{
                [theme.breakpoints.up("md")]: {
                  position: "absolute",
                  right: 0,
                  minWidth: "33%",
                  height: "100%",
                },
                // [theme.breakpoints.down("md")]: {
                //   height: "50vh",
                // },
              }}
            >
              <ChatBox />
            </Grid>
          </ChatContextProvider>
        )}
      </Grid>
    </BleacherContextProvider>
  );
}

export default Bleacher;
