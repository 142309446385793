import { API, RouterContextProvider } from "@tatami/shared";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthProvider, useFirebaseApp } from "reactfire";
import { FightEmbeddedProps } from ".";
import Main from "./views/fight/Main";

export interface AppProps {
  config: FightEmbeddedProps;
}

export interface Session {
  sid: string;
}

const fetchSession = () => API.get<Session>("/session");

function App({ config }: AppProps) {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const navigate = useNavigate();

  const [sessionSid, setSessionSid] = useState<string>();

  useEffect(() => {
    if (process.env.NODE_ENV === "development" && auth) {
      connectAuthEmulator(auth, "http://127.0.0.1:9099", {
        disableWarnings: true,
      });
    }
  }, [auth]);

  useEffect(() => {
    fetchSession().then((response) => setSessionSid(response.data.sid));
  }, []);

  if (!sessionSid) return null;
  return (
    <RouterContextProvider value={{ navigate }}>
      <AuthProvider sdk={auth}>
        <Routes>
          <Route
            path="*"
            element={
              <Main
                title={config.title}
                chat={config.chat}
                fightUid={config.fightUid}
                playable={config.playable}
              />
            }
          />
        </Routes>
      </AuthProvider>
    </RouterContextProvider>
  );
}

export default App;
