/** @jsxImportSource @emotion/react */

import { Typography } from "@mui/material";
import { useQuery } from "@tatami/shared";
import Bleacher from "./Bleacher";

export interface MainProps {
  fightUid: string;
  chat: boolean;
  title: boolean;
  playable?: boolean;
}

function Main({ fightUid, title, chat, playable }: MainProps) {
  const query = useQuery();
  const finalUid = query.get("f") || fightUid;

  if (!finalUid) return <Typography>No Fight UID</Typography>;

  return (
    <Bleacher
      titleEnabled={title}
      chatEnable={chat}
      fightUid={finalUid}
      playable={playable}
    />
  );
}

export default Main;
