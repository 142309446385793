import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { initReactI18next } from "react-i18next";

import HttpApi from "i18next-http-backend";

export const availableLanguages = ["en", "de", "es", "gl"];

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
    preload: ["en"],
    fallbackLng: "en",
    supportedLngs: availableLanguages,
    debug: process.env.NODE_ENV === "development",

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
