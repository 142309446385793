/** @jsxImportSource @emotion/react */

import { FightVideo, useBleacherContext } from "@tatami/shared";

function Fight({ playable, titleEnabled }: BleacherProps) {
  const { fight, media } = useBleacherContext();

  if (!media) return null;

  return (
    <div>
      <FightVideo
        titleEnabled={titleEnabled}
        title={fight.title}
        playbackUrl={media.playbackUrl}
        leftUserUid={media.leftSeatUserUid}
        rightUserUid={media.rightSeatUserUid}
        mediaMode={media.mode}
        playable={playable}
        controls={playable}
      />
    </div>
  );
}

type BleacherProps = {
  titleEnabled: boolean;
  playable: boolean;
} & typeof defaultProps;

const defaultProps = {
  playable: true,
};

Fight.defaultProps = defaultProps;

export default Fight;
